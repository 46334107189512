import { useEffect, useState } from "react";
import { recreateClient } from "../gitlabApi/client";
import { getUserInfo } from "../gitlabApi/getUserInfo";
import { useAppDispatch } from "../redux/store";
import { setUser } from "../redux/userSlice";

export const useUserInfo = (token: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const act = async () => {
      try {
        setLoading(true);
        const info = await getUserInfo(token);
        localStorage.setItem("token", token);
        recreateClient();
        dispatch(setUser(info));
        setError(undefined);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    if (token) act();
  }, [dispatch, token]);
  return { loading, error };
};
