import { Product } from "../hooks/useProducts";
import { gitlabClient } from "./client";
import { Issue } from "./getProjectIssues";

interface GitlabIssue {
  web_url: string;
  iid: number;
  title: string;
  project_id: number;
  updated_at: string;
  milestone: {
    title: string;
  } | null;
}

const processIssues = (data: GitlabIssue[], products: Product[]) => {
  const projectedIssues = data.map((d) => ({
    webUrl: d.web_url,
    iid: d.iid,
    title: d.title,
    updatedAt: new Date(d.updated_at),
    milestone: d.milestone,
    project: products
      .flatMap((p) => p.projects)
      .find((p) => p.id === d.project_id),
  }));
  return projectedIssues.filter((p) => !!p.project) as Issue[];
};

export const getIssues = async (
  products: Product[],
  maxIssues: number
): Promise<Issue[]> => {
  try {
    let loop;
    let page = 1;
    let fetched = 0;
    const issues: Issue[] = [];
    do {
      const res = await gitlabClient.get(`issues`, {
        params: {
          per_page: maxIssues,
          order_by: "updated_at",
          scope: "all",
          page,
        },
      });
      page++;
      const data: GitlabIssue[] = res.data;
      fetched += data.length;
      issues.push(...processIssues(data, products));
      loop = fetched < maxIssues;
    } while (loop);
    return issues;
  } catch (e: any) {
    if (e?.response?.status === 404) {
      // not enough permissions
      console.error(e);
      return [];
    }
    throw e;
  }
};
