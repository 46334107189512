import { Project } from "../hooks/useProducts";
import { gitlabClient } from "./client";

export interface Issue {
  webUrl: string;
  iid: number;
  title: string;
  project: Project;
  updatedAt: Date;
  milestone: {
    title: string;
  } | null;
}

export const getProjectIssues = async (
  project: Project,
  maxIssues: number
): Promise<Issue[]> => {
  try {
    const res = await gitlabClient.get(`projects/${project.id}/issues`, {
      params: {
        per_page: maxIssues,
        order_by: "updated_at",
      },
    });
    const data: [
      {
        web_url: string;
        iid: number;
        title: string;
        updated_at: string;
        milestone: {
          title: string;
        } | null;
      }
    ] = res.data;
    return data.map((d) => ({
      webUrl: d.web_url,
      iid: d.iid,
      title: d.title,
      updatedAt: new Date(d.updated_at),
      milestone: d.milestone,
      project,
    }));
  } catch (e: any) {
    if (e?.response?.status === 404) {
      // not enough permissions
      console.error(e);
      return [];
    }
    throw e;
  }
};
