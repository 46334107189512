import axios from "axios";
const create = () =>
  axios.create({
    baseURL: process.env.REACT_APP_GITLABURL,
    headers: { "PRIVATE-TOKEN": localStorage.getItem("token") },
  });

export let gitlabClient = create();

export const recreateClient = () => {
  gitlabClient = create();
};
