import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ReportData, ReportRecord } from "../hooks/useReport";
import { Product } from "../hooks/useProducts";
export interface ProductState {
  product?: Product;
  report: ReportData;
  combineIssues: boolean;
  maxIssues: number;
}

const initialState: ProductState = {
  product: undefined,
  combineIssues: localStorage.getItem("CombineIssues") !== "false",
  maxIssues: Number(
    localStorage.getItem("MaxIssues") ?? process.env.REACT_APP_ISSUES_MAX_LOAD
  ),
  report: { data: [] },
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct: (state, action: PayloadAction<Product>) => {
      state.product = action.payload;
    },
    setReportLoading: (state, action: PayloadAction<string | undefined>) => {
      state.report.loading = action.payload;
    },
    setReportError: (state, action: PayloadAction<string | undefined>) => {
      state.report.error = action.payload;
    },
    setReportData: (state, action: PayloadAction<ReportRecord[]>) => {
      state.report.data = [...action.payload].map((r) => ({
        author: r.author,
        records: [...r.records],
        total: r.total,
      }));
    },
    setCombineIssues: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem("CombineIssues", action.payload ? "true" : "false");
      state.combineIssues = action.payload;
    },

    setMaxIssues: (state, action: PayloadAction<string>) => {
      let value = Number(action.payload);
      if (isNaN(value) || value <= 0) value = 0;
      if (value > 500) value = 500;
      localStorage.setItem("MaxIssues", value.toString());
      state.maxIssues = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProduct,
  setReportData,
  setReportError,
  setReportLoading,
  setCombineIssues,
  setMaxIssues,
} = productSlice.actions;

export const productReducer = productSlice.reducer;
