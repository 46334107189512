import { Box, AppBar, Grid, Paper, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UserSelector } from "./UserSelector";
import { ClockWrap } from "./ClockWrap";
import { useIsBigScreen } from "../../hooks/useResponsive";
import React from "react";

const ItemBig = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#f9f9f9",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: "calc(100vh - 101px)",
  height: "calc(100% - 53px)",
  overflow: "hidden",
}));

const ItemSmall = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#f9f9f9",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  overflow: "hidden",
}));

const Bar = React.memo(() => {
  return (
    <AppBar position="static" color="secondary">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
        }}
      >
        Gitlab Time Tracker
      </Box>
    </AppBar>
  );
});

const Selector = React.memo(() => <UserSelector />);

const Tracker = React.memo(() => <ClockWrap />);

export const MainPage: React.FC = () => {
  const isBig = useIsBigScreen();
  return (
    <>
      {isBig && (
        <>
          <Bar />
          <Grid container spacing={0} sx={{ background: "#9c27b022" }}>
            <Grid item xs={3}>
              <ItemBig>
                <Selector />
              </ItemBig>
            </Grid>
            <Grid item xs={9}>
              <ItemBig>
                <Stack spacing={2}>
                  <Tracker />
                </Stack>
              </ItemBig>
            </Grid>
          </Grid>
        </>
      )}
      {!isBig && (
        <>
          <Bar />
          <Stack direction="column">
            <ItemSmall>
              <Selector />
            </ItemSmall>
            <ItemSmall>
              <Tracker />
            </ItemSmall>
          </Stack>
        </>
      )}
    </>
  );
};
