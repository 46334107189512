import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface RangeState {
  from: Date;
  to: Date;
  changed: boolean;
}
const beginOfDay = (d: Date) => {
  const res = new Date(d);
  res.setHours(0);
  res.setMinutes(0);
  res.setSeconds(0);
  return res;
};

const endOfDay = (d: Date) => {
  const res = new Date(d);
  res.setHours(23);
  res.setMinutes(59);
  res.setSeconds(59);
  return res;
};

const from = beginOfDay(new Date());
from.setDate(from.getDate() - 13);
const to = endOfDay(new Date());

const initialState: RangeState = {
  changed: false,
  from,
  to,
};

export const rangeSlice = createSlice({
  name: "range",
  initialState,
  reducers: {
    setFromRange: (state, action: PayloadAction<Date>) => {
      let newFrom = beginOfDay(action.payload);
      if (newFrom.getTime() >= state.to.getTime()) return;
      state.from = newFrom;
      state.changed = true;
    },
    setToRange: (state, action: PayloadAction<Date>) => {
      let newTo = endOfDay(action.payload);
      if (newTo.getTime() <= state.from.getTime()) return;
      state.to = newTo;
      state.changed = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFromRange, setToRange } = rangeSlice.actions;

export const rangeReducer = rangeSlice.reducer;
