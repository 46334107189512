import { Avatar, TextField, CircularProgress } from "@mui/material";
import { Stack } from "@mui/material";
import { useState } from "react";
import { useUserInfo } from "../../hooks/useUserInfo";
import { useAppSelector } from "../../redux/store";
import { ProjectSelector } from "./ProjectSelector";

export const UserSelector: React.FC = () => {
  const [token, setToken] = useState(localStorage.getItem("token") ?? "");
  const { loading, error } = useUserInfo(token);
  const user = useAppSelector((s) => s.user.user);
  return (
    <Stack direction={"column"} spacing="20px">
      <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={1}>
        <TextField
          fullWidth
          error={!!error}
          value={token}
          label="Access Token"
          onChange={(e) => setToken(e.target.value)}
        />
        {loading && (
          <div>
            <CircularProgress size={40} />
          </div>
        )}
        {user && !loading && (
          <Avatar
            component={"a"}
            href={user.url}
            target="_blank"
            rel="noopener noreferrer"
            src={user.avatar}
          ></Avatar>
        )}
      </Stack>
      {user && <ProjectSelector />}
    </Stack>
  );
};
