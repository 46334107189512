import { gitlabClient } from "./client";

const getGroupSubGroups = async (groupId: number): Promise<number[]> => {
  try {
    if (!localStorage.getItem("token")) return [];
    const res = await gitlabClient.get(`groups/${groupId}/subgroups`);
    const data: { id: number }[] = res.data;
    return data.map((p) => p.id);
  } catch (e: any) {
    if (e?.response?.status === 404) {
      // not enough permissions
      console.error(e);
      return [];
    }
    throw e;
  }
};

const getGroupGroups = async (groupId: number): Promise<number[]> => {
  try {
    const groupSubGroups = await getGroupSubGroups(groupId);
    const allSubgroupsPromise = groupSubGroups.map((g) => getGroupGroups(g));
    const allSubgroups = await Promise.all(allSubgroupsPromise);
    return [groupId, ...allSubgroups.flat()];
  } catch (e: any) {
    if (e?.response?.status === 404) {
      // not enough permissions
      console.error(e);
      return [];
    }
    throw e;
  }
};

const getOneGroupProjects = async (groupId: number): Promise<number[]> => {
  try {
    if (!localStorage.getItem("token")) return [];
    const res = await gitlabClient.get(`groups/${groupId}`);
    const data: { projects: { id: number }[] } = res.data;
    return data.projects.map((p) => p.id);
  } catch (e: any) {
    if (e?.response?.status === 404) {
      // not enough permissions
      console.error(e);
      return [];
    }
    throw e;
  }
};

export const getGroupProjects = async (groupId: number): Promise<number[]> => {
  try {
    if (!localStorage.getItem("token")) return [];
    const groups = await getGroupGroups(groupId);
    const projects = await Promise.all(
      groups.map((gId) => getOneGroupProjects(gId))
    );
    return projects.flat();
  } catch (e: any) {
    if (e?.response?.status === 404) {
      // not enough permissions
      console.error(e);
      return [];
    }
    throw e;
  }
};
