import { useCallback, useEffect, useState } from "react";
import { ClockComment, getClockRecords } from "../gitlabApi/getClockRecords";
import { getIssues } from "../gitlabApi/getIssues";
import { getProjectIssues, Issue } from "../gitlabApi/getProjectIssues";
import { UserInfo } from "../gitlabApi/getUserInfo";
import {
  setReportData,
  setReportError,
  setReportLoading,
} from "../redux/productSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { whiteListUsers } from "../utils/whiteListUsers";
import { Product, productAll, useProducts } from "./useProducts";

interface ReportParams {
  product: Product;
  from: Date;
  to: Date;
}

type RecordFunc = (params: ReportParams) => void;

export interface ReportRecord {
  author: UserInfo;
  total: number;
  records: ClockComment[];
}

export interface ReportData {
  loading?: string;
  error?: string;
  data: ReportRecord[];
}

export const useReportGenerate = (): RecordFunc => {
  const dispatch = useAppDispatch();
  const { products, loading: productsLoading } = useProducts();
  const { maxIssues, product } = useAppSelector((s) => ({
    product: s.product.product,
    maxIssues: s.product.maxIssues,
  }));
  const username = useAppSelector((s) => s.user.user?.username?.toLowerCase());
  const wl = whiteListUsers.indexOf(username ?? "") >= 0;
  const [f, sf] = useState(0);
  useEffect(() => {
    if (f === 0) {
      //skip first render
      sf(1);
      return;
    }
    dispatch(setReportError(undefined));
    dispatch(setReportData([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, product]);

  const action = useCallback(
    async ({ from, to, product }: ReportParams) => {
      dispatch(setReportError(undefined));
      dispatch(setReportData([]));
      try {
        let newData: ReportRecord[] = [];
        const processIssues = async (issues: Issue[]) => {
          let i = 0;
          let danger = true;
          for (const issue of issues) {
            danger = danger && issue.updatedAt.getTime() > from.getTime();
            dispatch(
              setReportLoading(`${issue.project.name}: ${i++}/${issues.length}`)
            );
            const recordsRaw = await getClockRecords(issue);
            const records = recordsRaw.filter(
              (r) =>
                r.updatedAt.getTime() >= from.getTime() &&
                r.updatedAt.getTime() <= to.getTime()
            );
            for (const record of records) {
              if (!wl && record.author.username.toLowerCase() !== username)
                continue;
              const target = newData.find(
                (r) => r.author.username === record.author.username
              );
              if (target) {
                target.total += record.spend;
                target.records.push(record);
              } else {
                let res: ReportRecord = {
                  author: record.author,
                  records: [record],
                  total: record.spend,
                };
                newData.push(res);
              }
            }
            dispatch(setReportData(newData));
          }
          if (danger) {
            dispatch(
              setReportError(
                "All issues are updated later than 'from' date. Possible incorrect report"
              )
            );
          }
          return danger;
        };
        let dangerAny = false;
        if (product.name !== productAll) {
          for (const project of product.projects) {
            dispatch(setReportLoading(project.name));
            const issues = await getProjectIssues(project, maxIssues);
            const dangerIssues = await processIssues(issues);
            dangerAny = dangerIssues || dangerAny;
          }
        } else {
          if (productsLoading) {
            throw new Error("Products are loading, wait a bit");
          }
          dispatch(setReportLoading(`Process last ${maxIssues} issues`));
          const issues = await getIssues(products, maxIssues);
          dangerAny = await processIssues(issues);
        }
        if (newData.length === 0 && !dangerAny) {
          dispatch(setReportError("There are no clock records!"));
        }
      } catch (err: any) {
        dispatch(setReportError(err.message));
      } finally {
        dispatch(setReportLoading(undefined));
      }
    },
    [dispatch, maxIssues, products, productsLoading, username, wl]
  );
  return action;
};
